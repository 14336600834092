import Vue from 'vue'
import App from './App.vue'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import jQuery from 'jquery'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Axios from 'axios'
import Swal from 'sweetalert2'
import Verte from 'verte';

import html2canvas from 'html2canvas'

global.jQuery = jQuery
global.$ = jQuery

Vue.prototype.$axios = Axios
Vue.prototype.$swal = Swal
Vue.prototype.$canvas = html2canvas

require("@/assets/vendor/font-awesome/css/font-awesome.css")
require("@/assets/vendor/verte/verte.css")
require("@/assets/style.css")

Vue.use(Verte);



Vue.component(Cropper)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
