<template>
	<div>
		<div class="image_deck" :style="'width: ' + width + '; height: ' + height + ';'" @click="abrirModalEnviarFoto">
			<img :src="image == null ? '' : image" v-if="image != null" class="w-100 h-100" @error="imageError" />
			<div v-else style="font-size: 28px; text-align: center; font-weight: bold;"
				:style="'margin-top: calc( ' + height + ' / 2 - 28px )'">+</div>
		</div>

		<div class="modal fade" :id="indentificador" tabindex="-1" data-backdrop="static"
			aria-labelledby="modalEditarFotoLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
				<div class="modal-content h-100">
					<div class="modal-header">
						<h5 class="modal-title h4" id="modalEditarFotoLabel">Imagem de Fundo</h5>
						<button type="button" class="close" @click="fechar" aria-label="Close"><span
								aria-hidden="true">×</span></button>
					</div>
					<div class="modal-body">

						<nav>
							<div class="nav nav-tabs" id="nav-tab" role="tablist">
								<button class="nav-link active" id="selecionar_imagem_tab" data-toggle="tab"
									data-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
									aria-selected="true">Selecionar Imagem</button>
								<button class="nav-link" id="fazer_upload_imagem_tab" data-toggle="tab"
									data-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact"
									aria-selected="false">Fazer Upload</button>
							</div>
						</nav>
						<div class="tab-content" id="nav-tabContent">
							<div class="tab-pane fade show active" id="nav-home" role="tabpanel"
								aria-labelledby="selecionar_imagem_tab">
								<div class="row mt-1">
									<div class="col-4" style="padding: 1px !important;" v-for="fundo in list_fundos" :key="fundo.id">
										<img :src="fundo.url" style="width: 100%;" @click="selecionar_image(fundo)"/>
									</div>
									<button type="button" class="btn btn-primary btn-lg btn-block mt-2" @click="listarFundos">Carregar Mais +</button>
								</div>

							</div>

							<div class="tab-pane fade" id="nav-contact" role="tabpanel"
								aria-labelledby="fazer_upload_imagem_tab">

								<div class="card mt-2">
									<div class="card-body p-3">
										<div class="row m-0">

											<div class="col-12 px-1">
												<div class="row">
													<div :class="imageTemporary != null ? 'col-xl-6' : 'col-12'">
														<div class="form-group file-area">
															<label for="images" class="font-15 mr-2"><i
																	class="fa fa-image color-theme font-13 mr-1"></i>
																Upload:
															</label>
															<input type="file" class="dropify" name="images" id="images"
																ref="images" accept=".jpg,.jpeg,.png,.webp"
																required="required" @change="onFileChange"
																@drop="onFileChange" @click="onFileChange" />
															<div class="file-dummy">
																<div class="default">.jpg or .png</div>
															</div>
														</div>
													</div>

													<div class="col-xl-6 mx-auto pt-3 mt-1 position-relative"
														v-if="imageTemporary != null">
														<div class="cropper-box">
															<cropper class="cropper" :src="imageTemporary"
																:stencil-props="AspectRatio" :auto-zoom="true"
																:backgroundClass="'bg-white'" @change="change" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>


							</div>
						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-round btn-primary" @click="salvarFoto">Fechar</button>
						<!-- <button type="button" class="btn btn-round btn-danger" @click="fechar">Cancel</button> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
import $ from 'jquery'

export default {
	name: 'UploadImageDeck',
	props: ["indentificador", "minAspectRatio", "maxAspectRatio", "width", "height", "model", "url_rest"],
	data: function () {
		return {
			image: this.model,
			imageTemporary: null,
			AspectRatio: {
				minAspectRatio: this.minAspectRatio,
				maxAspectRatio: this.maxAspectRatio
			},
			list_fundos: [],
			page_listagem_fundo: 1,
			foi_upload: false
		}
	},
	watch: {
		model: function(value) {
			this.image = value
		}
	},
	methods: {
		imageError: function (e) {
			this.toDataURL(e.url, function (image64) {
				this.$store.dispatch('imageError', image64)
			})
		},
		listarFundos: function () {

			axios({
				method: 'get',
				url: this.url_rest + 'listarFundosTop30',
				headers: {
					'Content-Type': 'application/json'
				},
				params: {
					order: this.page_listagem_fundo,
				}
			}).then((response) => {
				console.log(response)
				//this.list_fundos.push(...response.data)
				response.data.forEach(element => {
					//let tempElement = element
					//tempElement.url = tempElement.url;
					this.list_fundos.push(element)
				});

				this.page_listagem_fundo = this.page_listagem_fundo + 1
			}).catch(function (error) {
				console.log(error)
			}).finally(() => { });
		},
		change: function ({ coordinates, canvas }) {
			console.log("Selecionando Upload")
			this.foi_upload = true
			coordinates;
			this.$refs.images.value = null;
			this.image = canvas.toDataURL('image/jpeg');
		},
		onFileChange: function (e) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length)
				return;
			this.createImage(files[0]);
		},
		createImage: function (file) {
			var reader = new FileReader();
			var vm = this;

			reader.onload = (e) => {
				vm.imageTemporary = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		abrirModalEnviarFoto: function () {
			$("#" + this.indentificador).modal('show')
		},
		salvarFoto: function () {
			this.$emit("attValor", { image: this.image, upload: this.foi_upload })
			$("#" + this.indentificador).modal('hide')
		},
		selecionar_image: function (fundo) {
			this.foi_upload = false
			this.getDataUri(fundo.url, (dataUri) => {
				this.image = dataUri
				this.$emit("attValor", { image: this.image, upload: this.foi_upload })
			})

		},
		fechar: function () {
			$("#" + this.indentificador).modal('hide')
		},
		getDataUri(src, callback, outputFormat) {
			let image = new Image()
			image.crossOrigin = 'Anonymous';
			image.onload = function () {
				let canvas = document.createElement('canvas');
				let ctx = canvas.getContext('2d');
				let dataURL;
				canvas.height = this.naturalHeight;
				canvas.width = this.naturalWidth;
				ctx.drawImage(this, 0, 0);
				dataURL = canvas.toDataURL(outputFormat);
				callback(dataURL);
			};
			image.src = src;
			if (image.complete || image.complete === undefined) {
				image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
				image.src = src;
			}
		}
	},
	created() {
		this.listarFundos()
	}
}
</script>

<style scoped>
.image_deck {
	border: 2px dashed #6b6b6b;
}

.image_deck img {
	object-fit: contain;
}
</style>