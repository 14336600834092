<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 bg-primary text-white pt-2">
          <h1>😎 Mensagens de Bom dia 🌞</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <MsgLista v-if="false" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
					<MsgCreator></MsgCreator>
        </div>
      </div>



    </div>
    

    
  </div>
</template>

<script>
import MsgLista from './components/MsgLista.vue'
import MsgCreator from './components/MsgCreator.vue'

export default {
  name: 'App',
  components: {
    MsgLista, MsgCreator
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  padding: 0;
}
h1 {
  font-size: 24px !important;
  font-weight: bold !important;
}
</style>
